import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid, Paper, TableRow } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import * as React from 'react';



function createData(requested_by, profession, speciality, subSpeciality, email, phone, time, status) {
    return { requested_by, profession, speciality, subSpeciality, email, phone, time, status };
}

const rows = [
    createData('Ahmed Hanafy', "Physician", "Trauma", "Spin", "Ahmedhanafy925@gmail.com", "+966556789", "9th Jul 2024 09:37 PM", "Approved"),
    createData('Noha Alawdy', "Medicine Resident", "Medicine", "Diet", "noha@gmail.com", "+966584296", "11th Sept 2024 10:40 PM", "Pending"),

];
const card = (
    <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', borderRadius: '' }}>
            <Typography style={{ fontWeight: '700', fontSize: '24px' }}>
                Admin Requests
            </Typography>

            <Button variant='outlined'
                endIcon={
                    <KeyboardArrowDownIcon />
                }
                disableElevation style={{ backgroundColor: '#FCFDFD', borderColor: '#D4D4D4', textTransform: 'none', color: '#D4D4D4' }}>
                October
            </Button>
        </div>
        <div style={{ padding: '20px' }}>
            <TableContainer component={Paper} elevation={0} style={{ borderRadius: '10px' }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead style={{ backgroundColor: '#F1F4F9' }} borderRadius="10px">
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                        >
                            <TableCell>
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Requested By
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Profession
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Speciality
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Sub-Speciality
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Email
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Phone
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Date-Time
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Status
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.requested_by}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.profession}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.speciality}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.subSpeciality}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.email}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.phone}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.time}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        disableRipple
                                        style={{
                                            borderRadius: "10px",
                                            border: row.status === "Approved" ? "1px solid #00C3B8" : "1px solid #F29006",
                                            height: '30px',
                                            backgroundColor: row.status === "Approved" ? '#00C3B8' : "#F29006", height: '30px',
                                            height: "30px",
                                            margin: "5px",
                                            color: "white",
                                            cursor: "default",
                                            textTransform: "none",
                                        }}
                                    >
                                        {row.status}
                                    </Button>
                                    {/* <div style={{
                                        backgroundColor: row.status === "Approved" ? '#00C3B8' : "#F29006", height: '30px',
                                        borderRadius: '13px'
                                    }}>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                color: "white",
                                                padding: '5px',
                                                marginLeft: '5px'
                                            }}>
                                            {row.status}
                                        </Typography>
                                    </div> */}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </React.Fragment>
);

export default function OutlinedCard() {
    return (
        // <Grid item xs={12} style={{ paddingTop: "20px" }}>
        <div>
            <Paper elevation={0} style={{
                width: "100%",
                overflowX: "auto",
                backgroundColor: 'white',
                borderRadius: '12px'
            }}>
                {card}
            </Paper>
            {/* <div style={{
                display: 'flex',
                justifyContent: 'space-between', padding: '10px'
            }}>
                <Typography
                    style={{
                        fontSize: '14px'
                    }}>
                    Showing 1-09 of 78
                </Typography>
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 2,
                        bgcolor: 'background.paper',
                        color: 'text.secondary',
                        '& svg': {
                            m: 1,
                        },
                    }}
                >
                    <ArrowBackIosIcon style={{ height: '15px', width: '15px' }} />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ArrowForwardIosIcon style={{ height: '15px', width: '15px' }} />
                </Box>
            </div> */}
        </div>
        // </Grid>
    );
}
