import {
  Box,
  Button,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "../../MuiComponent/TextField";
import { apiClient } from "../../config/apiClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import OuterLayout from "../../layouts/login/OuterLayout";
import { useAuth } from "../../providers/AuthProvider";
import { forgotPasswordValidation } from "../../validations/login-validate";
import { border } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  // inputLabel: {
  //     color: 'red',
  //     fontSize: "14px",
  //     fontWeight:600
  // },
  // inputLabel: {
  //     color: theme.palette.custom.heading,
  //     fontSize: "14px",
  //     fontWeight:600
  // },

  mainHeading: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#202224",
    padding: "0% 10% 0 10%",

    [theme.breakpoints.down("md")]: {
      padding: "10% 10% 0 10%",
    },
  },
  subHeading: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#202224",
    opacity: "69%",
    padding: "4% 10% 7% 10%",
  },
  emailField: {
    padding: "0 10% 7% 10%",
    height: "50px",
  },
  loginButton: {
    margin: "5% 10% 10% 10%",
    fontWeight: "600",
    borderRadius: "10px",
    width: "50%",
    height: "50%",
  },
}));

function ForgotPassword(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  const apiSource = useCancelRequest();
  const forgotApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const classes = useStyles();
  let { from } = location.state || { from: { pathname: "/" } };

  let { values, touched, errors, handleBlur, submitForm, handleChange } =
    useFormik({
      initialValues: {
        email: "",
      },
      onSubmit: () => {
        forgotPassword();
      },
      validationSchema: forgotPasswordValidation,
    });

  let forgotPassword = async () => {
    let apiBody = {
      email: values.email,
      user_type: "admin",
      action_type: "forgot_password",
    };

    try {
      const result = await forgotApiStatus.run(
        apiClient("POST", "common", "/unauth/sendresendotp", {
          body: apiBody,
          shouldUseDefaultToken: true,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      let {
        content: { wait_time },
      } = result;
      console.log(result)

      notification.enqueueSnackbar("Email has been sent", {
        variant: "success",
      });
      window.localStorage.setItem("wait_time", wait_time)
      window.localStorage.setItem(
        "otpData",
        JSON.stringify({
          from: "forgot-password",
          email: values.email,
          waitTime: wait_time,
        })
      );
      navigate("/otp-verification", {
        from: "forgot-password",
        email: values.email,
        waitTime: wait_time,
      });


    } catch (error) {
      notification.enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (auth.getUserId()) {
      navigate("/");
    }
  });

  return (
    <OuterLayout is_back_button={true} on_back="/login">
      <Box className={classes.mainHeading}>Forgot your password</Box>

      <Box className={classes.subHeading}>
        Not a problem. Please enter your registered email address to receive
        your one time password
      </Box>

      <Box>
        <TextField
          id="email"
          name="email"
          error={touched.email && errors.email}
          helperText={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          placeholder="Enter Email ID"
          className={classes.emailField}
          variant="outlined"
          inputProps={{
            style: {
              backgroundColor: '#F1F4F9',
              borderColor: '#D8D8D8',
              border: '1px solid #D8D8D8 ',
              padding: "12px",
            },
          }}
        />
      </Box>

      <Box style={{ textAlign: "center" }}>
        <Button
          disabled={forgotApiStatus.isPending}
          onClick={submitForm}
          disableElevation
          variant="contained"
          className={classes.loginButton}
          style={{ backgroundColor: "#246AF1", color: "white" }}
        >
          {forgotApiStatus.isPending ? (
            <CircularProgress size={23} color="secondary" />
          ) : (
            "CONTINUE"
          )}
        </Button>
      </Box>
    </OuterLayout>
  );
}
export default ForgotPassword;
