import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OuterLayout from "../../layouts/login/OuterLayout";
import { useAuth } from "../../providers/AuthProvider";
// import logo from '../../assets/images/logo/newImage.svg'
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import TextField from "../../MuiComponent/TextField";
import { apiClient } from "../../config/apiClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { resetPasswordValidation } from "../../validations/login-validate";

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    fontSize: "30px",
    fontWeight: 600,
    color: "#202224",
    padding: "5% 10%",
  },
  passwordField: {
    margin: "4% 10%",
    height: "40px",
    width: "80%",
  },
  btn: {
    margin: "7% 10% 10% 10%",
    // height: '50%',
  },
  button: {
    width: "100%",
    height: "120%",
    borderRadius: "10px",
  },
}));

function ResetPassword(props) {
  let navigate = useNavigate();
  let auth = useAuth();

  let { values, touched, errors, handleBlur, submitForm, handleChange } =
    useFormik({
      initialValues: {
        password: "",
        confirm_password: "",
      },
      onSubmit: () => {
        resetpassword();
      },
      validationSchema: resetPasswordValidation,
    });

  const apiSource = useCancelRequest();
  const resetPasswordApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirm_password, setShowconfirm_password] = useState(false);

  useEffect(() => {
    // if (
    //   _.isUndefined(window.location.state) ||
    //   window.location.state.from != "otp-verification"
    // ) {
    //   navigate("/forgot-password");
    // }
  });

  let resetpassword = async () => {
    const datas = JSON.parse(window.localStorage.getItem("otpData"));
    console.log(datas)

    let apiBody = {
      new_password: values.password,
      email: datas.email,
      // otp,
    };
    console.log(apiBody, "api")

    try {
      const result = await resetPasswordApiStatus.run(
        apiClient("POST", "admin", "/unauth/changeadminpassword", {
          body: apiBody,
          shouldUseDefaultToken: true,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      // let {
      //   content: { data },
      // } = result;

      notification.enqueueSnackbar("Password Change Successfully", {
        variant: "success",
      });

      // if (data) 
      navigate("/login");
    } catch (error) {
      console.log("Reset Password Component Error: ", error);

      notification.enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  const classes = useStyles();

  useEffect(() => {
    // if (auth.getUserId()) {
    //   navigate("/");
    // }
  });

  return (
    <OuterLayout is_back_button={true} on_back="/otp-verification">
      <Box className={classes.mainHeading}>Create Password </Box>

      <Box>
        <TextField
          id="password"
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && errors.password}
          helperText={touched.password && errors.password}
          // type="password"
          value={values.password}
          className={classes.passwordField}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton
                style={{ color: '#EA0234' }}
                aria-label="Toggle password visibility"
                onClick={() => setShowPassword((prevValue) => !prevValue)}
              >
                {showPassword ? (
                  <VisibilityOff
                    fontSize="small"
                    style={{ color: "#EA0234" }}
                  />
                ) : (
                  <Visibility fontSize="small" style={{ color: "#EA0234" }} />
                )}
              </IconButton>
            ),
          }}
          // inputProps={{
          //   style: {
          //     backgroundColor: "#F1F4F9",
          //     color: "#D8D8D8",
          //     opacity: "90%",
          //     padding: "10px",
          //   },
          // }}
          type={showPassword ? "text" : "password"}
          placeholder="New Password"
        />
      </Box>

      <Box>
        <TextField
          id="confirm_password"
          name="confirm_password"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.confirm_password && errors.confirm_password}
          helperText={touched.confirm_password && errors.confirm_password}
          value={values.confirm_password}
          className={classes.passwordField}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton
                color="primary"
                aria-label="Toggle password visibility"
                onClick={() =>
                  setShowconfirm_password((prevValue) => !prevValue)
                }
              >
                {showconfirm_password ? (
                  <VisibilityOff
                    fontSize="small"
                    style={{ color: "#825ffc" }}
                  />
                ) : (
                  <Visibility fontSize="small" style={{ color: "#825ffc" }} />
                )}
              </IconButton>
            ),
          }}
          // inputProps={{
          //   style: {
          //     backgroundColor: "#303652",
          //     color: "white",
          //     opacity: "90%",
          //     padding: "10px",
          //   },
          // }}
          // type={showconfirm_password ? "text" : "password"}
          placeholder="Confirm Password"
        />
      </Box>

      <Box className={classes.btn}>
        <Button
          className={classes.button}
          disabled={resetPasswordApiStatus.isPending}
          onClick={submitForm}
          disableElevation
          variant="contained"
          style={{ color: "white", backgroundColor: '#246AF1' }}
        >
          {resetPasswordApiStatus.isPending ? (
            <CircularProgress size={23} />
          ) : (
            "Done"
          )}
        </Button>
      </Box>
    </OuterLayout>
  );
}

export default ResetPassword;
