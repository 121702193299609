import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import TextField from "../../MuiComponent/TextField";
import LockIcon from "@mui/icons-material/Lock";
import { changePasswordValidate } from "../../validations/login-validate";
import { useFormik } from "formik";
import useCancelRequest from "../../hooks/useCancelToken";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { apiClient } from "../../config/apiClient";
import { useAuth } from "../../providers/AuthProvider";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function ChangePassword() {
  const theme = useTheme();
  const globalClasses = useGeneralStyle();
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const {
    values,
    touched,
    errors,
    handleBlur,
    submitForm,
    handleChange,
    ...rest
  } = useFormik({
    initialValues: {
      old_password: null,
      new_password: null,
      confirm_password: null,
    },
    validationSchema: changePasswordValidate,
    onSubmit: () => {
      changePassword();
    },
  });

  const apiSource = useCancelRequest();
  const changePasswordApiStatus = useCallbackStatus();
  const auth = useAuth();
  const notification = useSnackbar();

  const changePassword = async () => {
    try {
      const result = await changePasswordApiStatus.run(
        apiClient("POST", "admin", "/unauth/changeadminpassword", {
          body: {
            admin_id: auth.getUserId(),
            new_password: values.new_password,
            old_password: values.old_password,
          },
          shouldUseDefaultToken: true,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      const {
        content: { data },
      } = result;

      notification.enqueueSnackbar("Password Change Successfully", {
        variant: "success",
      });
    } catch (err) {
      if (err && err.code === 403) {
        auth.logout();
      } else {
        notification.enqueueSnackbar(err.message, {
          variant: "err",
          autoHideDuration: 2000,
        });
      }
    }
  };

  return (
    <>
      <Box style={{ padding: theme.spacing(2) }}>
        {/* {JSON.stringify(values)} */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} style={{ marginBottom: "15px" }}>
            <Box display="flex" flexDirection="column">
              <InputLabel className={globalClasses.inputLabel}>
                Current Password
              </InputLabel>
              <TextField
                FormHelperTextProps={{ style: { fontSize: "10px" } }}
                id="old_password"
                name="old_password"
                style={{ backgroundColor: "#fff", height: "44px" }}
                error={touched.old_password && errors.old_password}
                helperText={touched.old_password && errors.old_password}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.old_password}
                placeholder="Enter you password"
                variant="outlined"
                type={showCurrentPassword ? "text" : "password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() =>
                        setCurrentShowPassword((prevValue) => !prevValue)
                      }
                    >
                      {showCurrentPassword ? (
                        <VisibilityOff
                          style={{ color: "black" }}
                          fontSize="small"
                          className={globalClasses.icon}
                        />
                      ) : (
                        <Visibility
                          style={{ color: "black" }}
                          fontSize="small"
                          className={globalClasses.icon}
                        />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} style={{ marginBottom: "15px" }}>
            <Box display="flex" flexDirection="column">
              <InputLabel className={globalClasses.inputLabel}>
                New Password
              </InputLabel>
              <TextField
                id="new_password"
                name="new_password"
                style={{ backgroundColor: "#fff", height: "44px" }}
                error={touched.new_password && errors.new_password}
                helperText={touched.new_password && errors.new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.new_password}
                FormHelperTextProps={{ style: { fontSize: "10px" } }}
                placeholder="Enter you password"
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() =>
                        setConfirmShowPassword((prevValue) => !prevValue)
                      }
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff
                          style={{ color: "black" }}
                          fontSize="small"
                          className={globalClasses.icon}
                        />
                      ) : (
                        <Visibility
                          style={{ color: "black" }}
                          fontSize="small"
                          className={globalClasses.icon}
                        />
                      )}
                    </IconButton>
                  ),
                }}
              />
              {!(touched.new_password && errors.new_password) && (
                <Typography
                  variant="caption"
                  style={{
                    fontSize: "11px",
                    fontWeight: 450,
                  }}
                >
                  Password must contain at least 8 characters, one uppercase,
                  one lowercase, and one digit
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" flexDirection="column">
              <InputLabel className={globalClasses.inputLabel}>
                Confirm Password
              </InputLabel>
              <TextField
                id="confirm_password"
                name="confirm_password"
                style={{ backgroundColor: "#fff", height: "44px" }}
                error={touched.confirm_password && errors.confirm_password}
                helperText={touched.confirm_password && errors.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirm_password}
                FormHelperTextProps={{ style: { fontSize: "10px" } }}
                placeholder="Enter you password"
                variant="outlined"
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() =>
                        setNewShowPassword((prevValue) => !prevValue)
                      }
                    >
                      {showNewPassword ? (
                        <VisibilityOff
                          style={{ color: "black" }}
                          fontSize="small"
                          className={globalClasses.icon}
                        />
                      ) : (
                        <Visibility
                          style={{ color: "black" }}
                          fontSize="small"
                          className={globalClasses.icon}
                        />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Button
        style={{
          position: "fixed",
          top: "7rem",
          right: "3rem",
          background: "#4379EE",
          color: "white",
        }}
        disableElevation
        variant="contained"
        disabled={changePasswordApiStatus.isPending ? true : false}
        onClick={submitForm}
      >
        {changePasswordApiStatus.isPending ? (
          <CircularProgress size={23} style={{ color: "#BAC8FF" }} />
        ) : (
          "Update"
        )}
      </Button>
    </>
  );
}

export default ChangePassword;
